import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import Fachada_progenie from '../img/localization.jpg';
import { Box } from '@mui/material';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${Fachada_progenie})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      
        <img
          style={{ display: 'none' }}
          src={Fachada_progenie}
          alt="increase priority"
        />
        <Typography color="inherit" align="center" variant="h3" marked="center">
          Especialistas em produzir os melhores resultados 💰 e desempenho 🥩 na pecuária
        </Typography>

      <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center'}}>
        <Typography
          color="inherit"
          align="center"
          variant="h6"
          sx={{ mb: 4, mt: 2, px: 1}}
          style= {{backgroundColor: 'black', borderRadius: '20px', fontWeight: '500'}}
        >
          Aqui a melhor oportunidade de negócio
        </Typography>
     
      
        
      </Box>
    </ProductHeroLayout>
  );
}
