import * as React from 'react';
import PRECISAO from '../img/PRECISAO.png';
import BENDITO_SAL from '../img/BENDITO-SAL.png';
import PETISCO from '../img/PETISCO.png';
import BOCA_CHEIA from '../img/BOCA-CHEIA.png';
import MERENDA from '../img/MERENDA.png';
import { Grid } from '@mui/material';
import { ProdutosCard } from './ProdutosCard';

export default function ProdutosRecria() {
  const products = [
    {
      id: 'precisao-6-0',
      image: PRECISAO,
      title: 'PRECISÃO 6.0'
    },
    {
      id: 'bendito-sal-recria',
      image: BENDITO_SAL,
      title: 'BENDITO SAL RECRIA'
    },
    {
      id: 'bendito-sal-ilpf',
      image: BENDITO_SAL,
      title: 'BENDITO SAL ILPF'
    },
    {
      id: 'petisco-jovem',
      image: PETISCO,
      title: 'PETISCO JOVEM'
    },
    {
      id: 'petisco-adulto',
      image: PETISCO,
      title: 'PETISCO ADULTO'
    },
    {
      id: 'petisco-seca',
      image: PETISCO,
      title: 'PETISCO SECA'
    },
    {
      id: 'merenda-jovem',
      image: MERENDA,
      title: 'MERENDA JOVEM'
    },
    {
      id: 'merenda-adulto',
      image: MERENDA,
      title: 'MERENDA ADULTO'
    },
    {
      id: 'merenda-campeiro',
      image: MERENDA,
      title: 'MERENDA CAMPEIRO'
    },
    {
      id: 'merenda-seca',
      image: MERENDA,
      title: 'MERENDA SECA'
    },
    {
      id: 'boca-cheia-recria',
      image: BOCA_CHEIA,
      title: 'BOCA CHEIA RECRIA'
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {products.map((product, i) => (
          <Grid key={product.id} item xs={12} sm={6} md={4}>
            <ProdutosCard
              imagem={product.image}
              titulo={product.title}
              mensagem={"Olá, estou interessado no produto " + product.title}>
            </ProdutosCard>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}