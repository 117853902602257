import { Header } from "../components/Header";
import * as React from 'react';
import ProductHero from '../views/ProductHero';
import { Parceiros } from '../components/Parceiros';
import Footer from "../components/Footer";

export function Home() {
  return (
    <React.Fragment>
      <Header />
      <ProductHero />
      <Parceiros/>
      <Footer />
    </React.Fragment>
  )
}
