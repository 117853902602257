import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box, Grid } from '@mui/material';
import { saveAs } from 'file-saver';

export function CardWithImage({ image, title, subtitle, buttonText, pdfPath }) {

  const onButtonClick = () => {
    saveAs(pdfPath, pdfPath.split('/').pop()); // Save the file with the original name
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%', mb: {md: 4} }}>
      <CardMedia
        component="img"
        sx={{ width: { xs: '100%', sm: '40%' }, height: { xs: 'auto', sm: '300px' }, objectFit: 'contain', p: 1 }}
        image={image}
        alt={title}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'left' }, p: 2 }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {subtitle}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, p: 2 }}>
          <Button variant="contained" color="success" onClick={onButtonClick} sx={{ color: '#fff' }}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Card>
  );
}


