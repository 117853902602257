import * as React from 'react';
import PREPARA from '../img/PREPARA.png';
import { Grid } from '@mui/material';
import { ProdutosCard } from './ProdutosCard';

export default function ProdutosPasto() {
  const products = [
    {
      id: 'prepara-0-1-e',
      image: PREPARA,
      title: 'PREPARA 0.1 E'
    },
    {
      id: 'prepara-0-1-ep',
      image: PREPARA,
      title: 'PREPARA 0.1 EP'
    },
    {
      id: 'prepara-seca-0-1-e',
      image: PREPARA,
      title: 'PREPARA SECA 0.1 E'
    },
    {
      id: 'prepara-seca-0-1-ep',
      image: PREPARA,
      title: 'PREPARA SECA 0.1 EP'
    },
    {
      id: 'prepara-0-3-e',
      image: PREPARA,
      title: 'PREPARA 0.3 E'
    },
    {
      id: 'prepara-0-3-ep',
      image: PREPARA,
      title: 'PREPARA 0.3 EP'
    },
    {
      id: 'prepara-1-0',
      image: PREPARA,
      title: 'PREPARA 1.0'
    },
    {
      id: 'prepara-total',
      image: PREPARA,
      title: 'PREPARA TOTAL'
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {products.map((product, i) => (
          <Grid key={product.id} item xs={12} sm={6} md={4}>
            <ProdutosCard
              imagem={product.image}
              titulo={product.title}
              mensagem={"Olá, estou interessado no produto " + product.title}>
            </ProdutosCard>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}