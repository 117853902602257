import React from 'react';
import { Typography, Box, Container, Grid, CardMedia } from '@mui/material';
import logo_abs from '../img/logo_abs.webp';
import logo_campo from '../img/logo_campo.png';

export function Parceiros() {
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Nossos Parceiros
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            height="150"
            sx={{
              width: { xs: '50%', sm: '100%' },
              filter: 'grayscale(100%)',
              '&:hover': {
                filter: 'none',
              },
              objectFit: 'contain',
            }}
            image={logo_campo}
            alt="Parceiro 1"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            height="150"
            sx={{
              width: { xs: '50%', sm: '100%' },
              filter: 'grayscale(100%)',
              '&:hover': {
                filter: 'none',
              },
              objectFit: 'contain',
            }}
            image={logo_abs}
            alt="Parceiro 2"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
