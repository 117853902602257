import * as React from 'react';
import { Header } from '../components/Header';
import ProductValues from '../views/ProductValues';
import Footer from '../components/Footer';

export function SobreNos() {
  return (
    <React.Fragment>
      <Header />
      <ProductValues />
      <Footer />
    </React.Fragment>
  )
}