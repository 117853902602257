import { Header } from "../components/Header";
import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';
import ProductHeroLayout from '../views/ProductHeroLayout';
import CapaAbs from '../img/BG-Capa2-2-2.jpg';
import Button from '../components/Button';
import Typography from '../components/Typography';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import { CardWithImage } from "../components/CardWithImage";
import Catalogo_Corte_Europeu from "../img/Catalogo_Corte_Europeu.webp";
import Catalogo_EUROPEU_CORTE_2024 from "../pdf/Catalogo_EUROPEU_CORTE_2024.pdf"
import Catalogo_Corte_Zebu from "../img/Catalogo_Corte_Zebu.webp";
import Catalogo_Corte_Zebu_23 from "../pdf/Catalogo_Corte_Zebu_23.pdf"
import Catalogo_Leite from "../img/Catalogo_Leite.webp"
import Catalogo_LE from "../pdf/Catalogo_LE.pdf"
import Catalogo_Leite_Tropical from "../img/Catalogo_Leite_Tropical.webp"
import LEITE_TROPICAL from "../pdf/LEITE_TROPICAL.pdf"
import { ProdutosCard } from "../components/ProdutosCard";
import BOTIJAO_TW20_IC_BIOTW20_IC_BIO from "../img/BOTIJAO_TW20_IC_BIOTW20_IC_BIO.webp";
import MVE_3418 from "../img/MVE_3418.webp";
import MVE_SC_117 from "../img/MVE_SC_117.webp";
import MVE_XC_4711 from "../img/MVE_XC_4711.webp";
import XC_20_S_Signature from "../img/XC_20_S_Signature.webp";
import Footer from "../components/Footer";

export function Abs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Header />
      <ProductHeroLayout
        sxBackground={{
          backgroundImage: `url(${CapaAbs})`,
          backgroundColor: '#7fc7d9', // Average color of the background image.
          backgroundPosition: 'center',
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <div>
          <img
          style={{ display: 'none' }}
          src={CapaAbs}
          alt="increase priority"
          />
         <Typography color="inherit" align="center" variant="h3" marked="center">
            Ninguém entende mais de <br /> Progresso Genético que a ABS
          </Typography>

          <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
          style= {{backgroundColor: 'black', borderRadius: '20px', fontWeight: '500'}}
          >
            Sêmen Convencional | Genética Sexada Sexcel| Embriões ABS NEO
          </Typography>
        </div>
      </ProductHeroLayout>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
              <Tab icon={<LibraryBooksOutlinedIcon sx={{ fontSize: 40 }} />} label="Catálogos" value="1" sx={{ fontSize: 18 }} />
              <Tab icon={<StoreOutlinedIcon sx={{ fontSize: 40 }} />} label="Produtos" value="2" sx={{ fontSize: 18 }} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Typography color="inherit" align="center" variant="h4" marked="center" sx={{ mb: { xs: 2, md: 4 } }}>
              Catálogos Leite
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CardWithImage buttonText={"Baixar PDF"} image={Catalogo_Leite_Tropical} pdfPath={LEITE_TROPICAL} subtitle={"2024"} title={"Leite Tropical"}></CardWithImage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardWithImage buttonText={"Baixar PDF"} image={Catalogo_Leite} pdfPath={Catalogo_LE} subtitle={"2023"} title={"Leite"}></CardWithImage>
              </Grid>
            </Grid>
            <Typography color="inherit" align="center" variant="h4" marked="center" sx={{ mb: { xs: 2, md: 4 }, mt: { xs: 2, md: 0 } }}>
              Catálogos Corte
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CardWithImage buttonText={"Baixar PDF"} image={Catalogo_Corte_Europeu} pdfPath={Catalogo_EUROPEU_CORTE_2024} subtitle={"2024"} title={"Corte Europeu"}></CardWithImage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardWithImage buttonText={"Baixar PDF"} image={Catalogo_Corte_Zebu} pdfPath={Catalogo_Corte_Zebu_23} subtitle={"2024"} title={"Corte Zebu"}></CardWithImage>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Typography color="inherit" align="center" variant="h4" marked="center" sx={{ mb: { xs: 2, md: 4 }, mt: { xs: 2, md: 0 } }}>
              Botijões
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <ProdutosCard
                  imagem={BOTIJAO_TW20_IC_BIOTW20_IC_BIO}
                  titulo={"BOTIJÃO IC BIOMEDICAL TW 20"}
                  paragrafos={[
                    "Doses de palhetas finas: 1.440 doses",
                    "Capacidade de nitrogênio: 20,5 kg",
                    "Garantia: 5 anos",
                  ]}
                  mensagem={"Olá, estou interessado no produto BOTIJÃO IC BIOMEDICAL TW 20"}>
                </ProdutosCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ProdutosCard
                  imagem={MVE_3418}
                  titulo={"BOTIJÃO DE NITROGÊNIO MVE 34/18"}
                  paragrafos={[
                    "Doses de palhetas finas: 1.440 doses",
                    "Capacidade de nitrogênio: 20,5 kg",
                    "Garantia: 5 anos",
                  ]}
                  mensagem={"Olá, estou interessado no produto BOTIJÃO DE NITROGÊNIO MVE 34/18"}>
                </ProdutosCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ProdutosCard
                  imagem={MVE_SC_117}
                  titulo={"BOTIJÃO DE NITROGÊNIO SC 11/7"}
                  paragrafos={[
                    "Doses de palhetas finas: 4.200 doses",
                    "Capacidade de nitrogênio: 34,8 kg",
                    "Garantia: 3 anos",
                  ]}
                  mensagem={"Olá, estou interessado no produto BOTIJÃO DE NITROGÊNIO SC 11/7"}>
                </ProdutosCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ProdutosCard
                  imagem={MVE_XC_4711}
                  titulo={"BOTIJÃO XC 47/11 (Incluso Nitrogênio + Sêmens)"}
                  paragrafos={[
                    "Doses de palhetas finas: 9.000 doses",
                    "Capacidade de nitrogênio: 47,4 kg",
                    "Garantia: 3 anos",
                  ]}
                  mensagem={"Olá, estou interessado no produto BOTIJÃO XC 47/11 (Incluso Nitrogênio + Sêmens)"}>
                </ProdutosCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ProdutosCard
                  imagem={XC_20_S_Signature}
                  titulo={"BOTIJÃO DE NITROGÊNIO XC 20 S (SIGNATURE)"}
                  paragrafos={[
                    "Doses de palhetas finas: 9.000 doses",
                    "Capacidade de nitrogênio: 47,4 kg",
                    "Garantia: 3 anos",
                  ]}
                  mensagem={"Olá, estou interessado no produto BOTIJÃO DE NITROGÊNIO XC 20 S (SIGNATURE)"}>
                </ProdutosCard>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
      <Footer />
    </React.Fragment>
  )
}