
import { Box } from '@mui/material';
import LocalizationImage from "../img/localization.jpg";
import * as React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from "leaflet";

const position = [-15.6078386, -56.1320593];
const skater = new Icon({
  iconUrl: "/map_marker.svg",
  iconSize: [25, 25]
});

export default function LocalizationCard() {
  return (
    <React.Fragment>
      <Box component={'section'} display={'flex'} justifyContent={'stretch'} alignItems={'center'} sx={{
        p: 4,
        boxShadow: '0px 0px 39px 13px rgba(0,0,0,0.1);',
        borderRadius: '3rem',
        flexDirection: { xs: 'column', md: 'row' },
      }}>
        <Box
          component="img"
          src={LocalizationImage}
          sx={{
            width: { xs: '100%', md: '50%' },
            height: { xs: 'auto', md: '40rem' },
            borderRadius: { xs: '3rem 3rem 0 0', md: '3rem 0 0 3rem' },
          }}
        />
        <Box sx={{
          width: { xs: '100%', md: '50%' },
          height: { xs: '18rem', md: '40rem' },
        }}>
          <MapContainer center={position} zoom={50}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={skater} title='Progênie'/>
          </MapContainer>
        </Box>
      </Box>
    </React.Fragment>
  );
}