import * as React from 'react';
import { Navigations } from "./routes";
import withRoot from './components/withRoot';
import './reset.css';
import 'leaflet/dist/leaflet.css';
import WhatsappFab from "./components/WhatsappFab.js"
function App() {
  React.useEffect(() => {
    document.title = "ProgenieMT";
  }, []);

  return (
    <div>
      <Navigations />
      <WhatsappFab/>
    </div>
  );
}

export default withRoot(App);