import * as React from 'react';
import PRECISAO from '../img/PRECISAO.png';
import BOCA_CHEIA from '../img/BOCA-CHEIA.png';
import GULOSO from '../img/GULOSO.png';
import { Grid } from '@mui/material';
import { ProdutosCard } from './ProdutosCard';

export default function ProdutosEngorda() {
  const products = [
    {
      id: 'precisao-4-0',
      image: PRECISAO,
      title: 'PRECISÃO 4.0'
    },
    {
      id: 'boca-cheia-sertanejo',
      image: BOCA_CHEIA,
      title: 'BOCA CHEIA SERTANEJO'
    },
    {
      id: 'boca-cheia-2',
      image: BOCA_CHEIA,
      title: 'BOCA CHEIA'
    },
    {
      id: 'guloso-2',
      image: GULOSO,
      title: 'GULOSO'
    },
    {
      id: 'guloso-sertanejo',
      image: GULOSO,
      title: 'GULOSO SERTANEJO'
    },
    {
      id: 'guloso-cp',
      image: GULOSO,
      title: 'GULOSO CP'
    },
    {
      id: 'guloso-sc',
      image: GULOSO,
      title: 'GULOSO SC'
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {products.map((product, i) => (
          <Grid key={product.id} item xs={12} sm={6} md={4}>
            <ProdutosCard
              imagem={product.image}
              titulo={product.title}
              mensagem={"Olá, estou interessado no produto " + product.title}>
            </ProdutosCard>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}