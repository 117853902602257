
import * as React from 'react';
import { Header } from '../components/Header';
import { Box, Container, Grid, Typography } from '@mui/material';
import IconContatoOne from '../img/Contato_Icon_1.svg';
import IconContatoTwo from '../img/Contato_Icon_2.svg';
import IconContatoThree from '../img/Contato_Icon_3.svg';
import Logo from '../img/Logo.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Footer from '../components/Footer';

function ContatoInfo({ icon, title, text, extraText = null, iconExtraText }) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box
        sx={{
          backgroundColor: '#D9D9D9',
          p: 4,
          borderRadius: 2,
          mb: 1,
        }}
      >
        <img
          src={icon}
          alt="Imagem"
          style={{ width: '3rem', height: 'auto' }}
        />
      </Box>
      <Typography variant="body1" sx={{ mb: 1, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: '#7B7575' }}
        width={'60%'}
        textAlign={'center'}
      >
        {text}
      </Typography>

      {extraText && extraText.map((text, index) => (
          <Grid container justifyContent="center" alignItems='center'> {/* Align items vertically within the container */}

          
          {text.icon === 'WhatsApp' && (
            <WhatsAppIcon sx={{mr: 1, color: 'green', cursor: 'pointer'}} onClick={() => {
              const formattedPhone = text.phone.replace(/[+\-\s]/g, '');
              window.open(`https://wa.me/${formattedPhone}`, '_blank'); // Ajuste no link do WhatsApp
            }}/>
          )}

          {text.icon === 'Phone' && (
             <LocalPhoneIcon sx={{mr: 1, cursor: 'pointer'}} onClick={() => {
              const formattedPhone = text.phone.replace(/[+\-\s]/g, '');
              window.open(`tel:${formattedPhone}`, '_blank');
            }}/>
          )}

          {text.icon === 'WhatsAppPhone' && (
            <>
              <WhatsAppIcon sx={{mr: 1, color: 'green', cursor: 'pointer'}} onClick={() => {
                const formattedPhone = text.phone.replace(/[+\-\s]/g, '');
                window.open(`https://wa.me/${formattedPhone}`, '_blank'); // Ajuste no link do WhatsApp
              }}/>
              <LocalPhoneIcon sx={{mr: 1, cursor: 'pointer'}} onClick={() => {
              const formattedPhone = text.phone.replace(/[+\-\s]/g, '');
              window.open(`tel:${formattedPhone}`, '_blank');
              }}/>
            </>
          )}
    
          <Typography
            variant="body2"
            sx={{ color: '#0A22FF', mt: 1, fontWeight: 600 }}
            textAlign="center" // Ensure horizontal centering
            key={index}
          >
            {text.phone}
          </Typography>
        </Grid>
      ))}
    </Box>
  );
}

export function Contato() {
  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="xl" sx={{ marginTop: { xs: '96px', md: '64px' } }}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{ mt: 4 }}
        >
          <Typography variant="h2" sx={{ mt: 6, mb: 2, fontSize: { xs: 40, md: 48 } }}>
            Contate-nos
          </Typography>
          <Typography variant="subtitle1" sx={{ width: '50%', mb: 6, textAlign: 'center', fontSize: { xs: 16, md: 26 } }}>          
            Nossa equipe técnica está pronta para participar do processo do seu negócio, pecuarista.
          </Typography>
        </Box>

        <Grid container spacing={2} mt={4} justifyContent={'center'}>
          <Grid item xs={12} md={4} lg={3}>
            <ContatoInfo
              icon={IconContatoOne}
              title="Informações da Empresa:"
              text="PROGENIE - COMERCIO DE PRODUTOS AGROPECUARIOS LTDA CNPJ: 11.687.115/0001-12"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <ContatoInfo
              icon={IconContatoTwo}
              title="Endereço:"
              text="AV. Ciriaco Candia, 228 Bairro Cidade Verde - Cuiabá/MT, CEP: 78028-770"
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <ContatoInfo
              icon={IconContatoThree}
              title="Entre em contato:"
              text="Ligue-nos para falar com um membro da nossa equipe, ou chame no WhatsApp.
              Nós estamos sempre felizes em ajudar."
              extraText={[{phone: '+55 65 99681-3092', icon: 'WhatsApp'}, {phone: '+55 65 99998-4214', icon: 'WhatsApp'}, {phone: '+55 65 3623-3090', icon: 'WhatsAppPhone'},]}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img src={Logo} style={{ height: 'auto' }} alt='Logo' />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
}