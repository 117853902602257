// src/components/WhatsappFab.js
import React from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material/styles';

const StyledFab = styled(Fab)({
    position: 'fixed',
    bottom: 16,
    right: 16,
  });

export default function WhatsappFab () {
  const handleClick = () => {
    window.open('https://wa.me/556536233090', '_blank');
  };

  return (
    <StyledFab color="success" onClick={handleClick}>
      <WhatsAppIcon sx={{ color: 'white' }}/>
    </StyledFab>
  );
};

