import { Button, Grid } from '@mui/material';
import * as React from 'react';

export default function SubTabButton({ label, value, selectedValue, onClick }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Button value={value} sx={{
        mx: 4,
        borderRadius: 0,
        border: 0,
        p: { xs: 2, md: 6},
        borderBottom: selectedValue === value ? '2px solid #82b55b' : '0',
        backgroundColor: '#eeeeee',
        width: '10rem',
      }} onClick={() => onClick(value)}>
        {label}
      </Button>
    </Grid>
  );
}