import React from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';

export function Produtos({ mainTitle, blocks }) {
  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        {mainTitle}
      </Typography>
      <Grid container spacing={2}>
        {blocks.map((block, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={block.image} alt={block.title} style={{ maxWidth: '100%' }} />
              <Typography variant="h6" gutterBottom>
                {block.title}
              </Typography>
              <Typography variant="body1">
                {block.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

