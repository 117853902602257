import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Logo from '../img/Logo.png';

const pages = [{
  name: 'SOBRE NÓS',
  href: '/sobrenos',
}, {
  name: 'ABS',
  href: '/abs',
}, {
  name: 'CAMPO NUTRIÇÃO',
  href: '/camponutricao',
}, {
  name: 'SOLUÇÕES',
  href: '#',
}, {
  name: 'LOCALIZAÇÃO',
  href: '/localization',
}, {
  name: 'CONTATO',
  href: '/contato',
}];

const socialLinks = [
  { icon: <YouTubeIcon />, href: 'https://www.youtube.com/@progenieagribusinessmt' },
  { icon: <InstagramIcon />, href: 'https://www.instagram.com/agribusinessprogenie/' },
  { icon: <FacebookIcon />, href: 'https://www.facebook.com/progenieagronegocio' },
  { icon: <LinkedInIcon />, href: 'https://www.linkedin.com/company/progenie-agribusiness/' },
];

export function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (hrefLink) => {
    setAnchorElNav(null);
    if (hrefLink) {
      navigate(hrefLink);
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img
              src={Logo}  // Ajuste o caminho da imagem conforme sua estrutura de projeto
              alt="Logo"
              style={{ height: 40, cursor: 'pointer' }}  // Ajuste o tamanho conforme necessário
              onClick={() => navigate('/')}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={() => handleCloseNavMenu(null)}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {pages.map((page, index) => (
          <MenuItem key={index} onClick={() => handleCloseNavMenu(page.href)}>
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          {socialLinks.map((link, index) => (
            <IconButton
              key={index}
              component="a"
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'black' }}
            >
              {link.icon}
            </IconButton>
          ))}
        </Box>
      </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <img
              src={Logo} // Ajuste o caminho da imagem conforme sua estrutura de projeto
              alt="Logo"
              style={{ height: 40, cursor: 'pointer' }}  // Ajuste o tamanho conforme necessário
              onClick={() => navigate('/')}
            />
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
            onClick={() => navigate('/')}
          >
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
      {pages.map((page, index) => (
        <Button
          key={index}
          onClick={() => handleCloseNavMenu(page.href)}
          sx={{ my: 2, color: 'black', display: 'block' }}
        >
          {page.name}
        </Button>
      ))}
      <Box sx={{ ml: 'auto' }}>
        {socialLinks.map((link, index) => (
          <IconButton
            key={index}
            component="a"
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'black' }}
          >
            {link.icon}
          </IconButton>
        ))}
      </Box>
    </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
