import { Header } from "../components/Header";
import * as React from 'react';
import MyCarousel from '../components/Carousel';
import { Box, Container, Grid, Tab } from '@mui/material';
import { Produtos } from '../components/Produtos';
import Icone_Minerais from '../img/Icone_Minerais.png';
import Icone_Nucleo from '../img/Icone_Nucleo.png';
import Icone_Proteicos from '../img/Icone_Proteicos.png';
import Icone_Racoes from '../img/Icone_Racoes.png';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ProntoUso from "../views/ProntoUso";
import ParaReparo from "../views/ParaReparo";
import Footer from "../components/Footer";

const importAll = (r) => {
  return r.keys().map(r);
}

const images = importAll(require.context('../img', false, /CampoNutricao.*\.(png|jpe?g|svg)$/));  // importação das imagens

export function CampoNutricao() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const blocksData = [
    {
      image: Icone_Minerais,
      title: 'Precisão e bentido sal',
      description: 'Com alta eficiência, supre as necessidades de minerais nas fases de reprodução, cria, recria e terminação.',
    },
    {
      image: Icone_Proteicos,
      title: 'Petisco merenda e boca cheia',
      description: 'Desenvolvido com tecnologia de ponta, proporciona alto desempenho produtivo. Trio que já comprovou a consistência de seus resultados.',
    },
    {
      image: Icone_Racoes,
      title: 'Guloso',
      description: 'Proporciona a adequada complementação em energia, proteínas e minerais, para bovinos de corte em fase de engorda.',
    },
    {
      image: Icone_Nucleo,
      title: 'Prepara',
      description: 'Núcleo para produção de ração e suplementos altamente balanceados, objetivando alta performance na nutrição de gado de corte.',
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="xxl" sx={{ marginTop: { xs: '96px', md: '64px' }, }}>
        <Grid justifyContent="center">
          <Grid item mb={4} xs={12} md={8} lg={6}>
            <MyCarousel images={images} />
          </Grid>
          <Container maxWidth="lg" >
            <Produtos
              mainTitle="Linhas de produtos"
              blocks={blocksData}
            />
          </Container>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} centered>
                  <Tab label="Pronto uso" value="1" sx={{ fontSize: 18 }} />
                  <Tab label="Para Preparo" value="2" sx={{ fontSize: 18 }} />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: { xs: 0, md: 3 } }}>
                <ProntoUso></ProntoUso>
              </TabPanel>
              <TabPanel value="2" sx={{ p: { xs: 0, md: 3 } }}>
                <ParaReparo></ParaReparo>
              </TabPanel>
            </TabContext>
          </Container>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
