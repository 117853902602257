import * as React from 'react';
import PREPARA from '../img/PREPARA.png';
import { Grid } from '@mui/material';
import { ProdutosCard } from './ProdutosCard';

export default function ProdutosConfinamento() {
  const products = [
    {
      id: 'prepara-avante',
      image: PREPARA,
      title: 'PREPARA AVANTE'
    },
    {
      id: 'prepara-avante-200',
      image: PREPARA,
      title: 'PREPARA AVANTE 200'
    },
    {
      id: 'prepara-arranque',
      image: PREPARA,
      title: 'PREPARA ARRANQUE'
    },
    {
      id: 'prepara-arremate',
      image: PREPARA,
      title: 'PREPARA ARREMATE'
    },
    {
      id: 'prepara-arremate-dg',
      image: PREPARA,
      title: 'PREPARA ARREMATE DG'
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {products.map((product, i) => (
          <Grid key={product.id} item xs={12} sm={6} md={4}>
            <ProdutosCard
              imagem={product.image}
              titulo={product.title}
              mensagem={"Olá, estou interessado no produto " + product.title}>
            </ProdutosCard>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}