import { Container, Grid } from '@mui/material';
import * as React from 'react';
import SubTabButton from '../components/SubTabButton';
import ProdutosCria from '../components/ProdutosCria';
import ProdutosRecria from '../components/ProdutosRecria';
import ProdutosEngorda from '../components/ProdutosEngorda';

export default function ProntoUso() {
  const [value, setValue] = React.useState("cria");

  const handleValue = (newValue) => {
    setValue(newValue);
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Grid container spacing={2}>
          <SubTabButton label="Cria" value="cria" selectedValue={value} onClick={handleValue} />
          <SubTabButton label="Recria" value="recria" selectedValue={value} onClick={handleValue} />
          <SubTabButton label="Engorda" value="engorda" selectedValue={value} onClick={handleValue} />
        </Grid>

        {value === 'cria' && (<ProdutosCria />)}
        {value === 'recria' && (<ProdutosRecria />)}
        {value === 'engorda' && (<ProdutosEngorda />)}
      </Container>
    </React.Fragment>
  )
}