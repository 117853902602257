import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import { Home } from "./pages/Home";
import { Localization } from "./pages/Localization";
import { CampoNutricao } from "./pages/CampoNutricao";
import { Contato } from "./pages/Contato";
import { Abs } from "./pages/Abs";
import { SobreNos } from "./pages/SobreNos";

export function Navigations() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          path="/"
          element={
            <Home />
          }
        />

        <Route
          index
          path="/sobrenos"
          element={
            <SobreNos />
          }
        />

        <Route
          index
          path="/abs"
          element={
            <Abs />
          }
        />

        <Route
          index
          path="/campoNutricao"
          element={
            <CampoNutricao />
          }
        />

        <Route
          index
          path="/localization"
          element={
            <Localization />
          }
        />

        <Route
          index
          path="/contato"
          element={
            <Contato />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
