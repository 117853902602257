import * as React from 'react';
import { Header } from '../components/Header';
import Typography from '../components/Typography';
import { Box, Container } from '@mui/material';
import LocalizationCard from '../views/LocalizationCard';
import Footer from '../components/Footer';

export function Localization() {
  return (
    <React.Fragment>
      <Header />

      <Container maxWidth="xl" sx={{ marginTop: { xs: '96px', md: '64px' } }}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{ mt: 4 }}
        >
          <Typography variant="h2" sx={{ mt: 6, mb: 2, fontSize: { xs: 40, md: 48 } }}>
            Localização
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center', fontSize: { xs: 16, md: 26 } }}>
            AV. Ciriaco Candia, 228 Bairro Cidade Verde - Cuiabá/MT
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: {xs: 2, md: 6}, textAlign: 'center', fontSize: { xs: 16, md: 26 } }}>
            CEP: 78028-770
          </Typography>
        </Box>

        <LocalizationCard />
      </Container>
      <Footer />
    </React.Fragment>
  )
}