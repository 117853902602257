import React from 'react';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';

export function ProdutosCard ({ imagem, titulo, paragrafos, mensagem }) {
  return (
    <Card>
      <CardMedia
        image={imagem}
        sx={{ width: { xs: '100%' }, height: { xs: '450px', sm: '300px', md: '250' }, objectFit: 'contain', p: 1, backgroundSize:'contain' }}
      />
      <CardContent sx={{textAlign: 'center'}}>
        <Typography variant="h6" gutterBottom >
          {titulo}
        </Typography>
        
        {paragrafos && paragrafos.map((paragrafo) => (
        <Typography variant="body2" key={paragrafo} sx={{ textAlign: 'center' }}>
            {paragrafo}
        </Typography>
        ))}
        
        <Button
            color="success"
            variant="contained"
            size="large"
            component="a"
            href={`https://wa.me/556536233090?text=${mensagem}`}
            sx={{ minWidth: 200, color: '#fff' }}
            target="_blank"
            rel="noopener noreferrer"
        >
            WhatsApp
        </Button>
      </CardContent>
    </Card>
  );
};
