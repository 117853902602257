import { Container, Grid } from '@mui/material';
import * as React from 'react';
import SubTabButton from '../components/SubTabButton';
import ProdutosPasto from '../components/ProdutosPasto';
import ProdutosConfinamento from '../components/ProdutosConfinamento';

export default function ParaReparo() {
  const [value, setValue] = React.useState("pasto");

  const handleValue = (newValue) => {
    setValue(newValue);
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
          <SubTabButton label="Pasto" value="pasto" selectedValue={value} onClick={handleValue} />
          <SubTabButton label="Confinamento" value="confinamento" selectedValue={value} onClick={handleValue} />
        </Grid>

        {value === 'pasto' && (<ProdutosPasto />)}
        {value === 'confinamento' && (<ProdutosConfinamento />)}
      </Container>
    </React.Fragment>
  )
}