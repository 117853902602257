import * as React from 'react';
import PRECISAO from '../img/PRECISAO.png';
import BENDITO_SAL from '../img/BENDITO-SAL.png';
import PETISCO from '../img/PETISCO.png';
import BOCA_CHEIA from '../img/BOCA-CHEIA.png';
import GULOSO from '../img/GULOSO.png';
import { Grid } from '@mui/material';
import { ProdutosCard } from './ProdutosCard';

export default function ProdutosCria() {
  const products = [
    {
      id: 'precisao-8-0',
      image: PRECISAO,
      title: 'PRECISÃO 8.0'
    },
    {
      id: 'precisao-16-0',
      image: PRECISAO,
      title: 'PRECISÃO 16.0'
    },
    {
      id: 'precisao-seca-10',
      image: PRECISAO,
      title: 'PRECISÃO SECA 10'
    },
    {
      id: 'precisao-seca-15',
      image: PRECISAO,
      title: 'PRECISÃO SECA 15'
    },
    {
      id: 'bendito-sal',
      image: BENDITO_SAL,
      title: 'BENDITO SAL'
    },
    {
      id: 'precisao-reproducao',
      image: PRECISAO,
      title: 'PRECISÃO REPRODUÇÃO'
    },
    {
      id: 'bendito-sal-reproducao',
      image: BENDITO_SAL,
      title: 'BENDITO SAL REPRODUÇÃO'
    },
    {
      id: 'petisquinho',
      image: PETISCO,
      title: 'PETISQUINHO'
    },
    {
      id: 'boca-cheia-1',
      image: BOCA_CHEIA,
      title: 'BOCA CHEIA REPRODUÇÃO'
    },
    {
      id: 'guloso-1',
      image: GULOSO,
      title: 'GULOSO CREEP'
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {products.map((product, i) => (
          <Grid key={product.id} item xs={12} sm={6} md={4}>
            <ProdutosCard
              imagem={product.image}
              titulo={product.title}
              mensagem={"Olá, estou interessado no produto " + product.title}>
            </ProdutosCard>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}