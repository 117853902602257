// Carousel.jsx
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';

const MyCarousel = ({ images }) => {
  return (
    <Carousel navButtonsAlwaysVisible indicators={false} fullHeightHover>
      {
        images.map((image, i) => <Item key={i} image={image} />)
      }
    </Carousel>
  )
}

const Item = ({ image }) => {
  return (
    <Paper style={{ position: 'relative' }}>
      <img
        src={image}
        alt={`carousel-item-${image}`}
        style={{
          display: 'block',
          objectFit: 'cover',
          height: '100%',
          width: '100%',
        }}
      />
    </Paper>
  )
}

export default MyCarousel;
