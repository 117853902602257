import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import ProductCurvyLines from '../img/productCurvyLines.png'
import icons8_goal_100 from '../img/icons8_goal_100.png';
import icons8_values_100 from '../img/icons8_values_100.png';
import icons8_vision from '../img/icons8_vision.png';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#f5fff6' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={ProductCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={icons8_goal_100}
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                  Missão
              </Typography>
              <Typography variant="h5" sx={{textAlign: 'justify'}}>
                Gerar lucro e produtividade na propriedade rural através de progresso genético do rebanho em sistemas de produção eficientes e adequados.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={icons8_vision}
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Visão
              </Typography>
              <Typography variant="h5" sx={{textAlign: 'justify'}}>
                Referência nacional de soluções, produtos e serviços para a pecuária produtiva.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={icons8_values_100}
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Valores
              </Typography>
              <Typography variant="h5">
                <ul>
                  <li>- Excelência em atendimento;</li>
                  <li>- Credibilidade e capacidade técnica;</li>
                  <li>- Responsabilidade e respeito aos clientes e fornecedores;</li>
                  <li>- Confiança e valorização dos colaboradores e parceiros.</li>
                  <li>- Inovação para melhorias continuas. </li>
                </ul>              
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
