import { Box, Container, Grid } from '@mui/material';
import Logo from '../img/Logo.png';
import * as React from 'react';
import Typography from './Typography';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <img
                src={Logo}
                alt="Logo"
                style={{ height: 40, cursor: 'pointer' }}
                onClick={() => navigate('/')}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {`2024 | Progênie Agribusiness MT`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}